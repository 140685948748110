@font-face {
    font-family: 'Gotham-book';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/GothamBookRegular.otf');
}

@font-face {
    font-family: 'SofiaPro-Regular';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/SofiaPro-Regular.woff2');
}

@font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/Figtree-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../assets/fonts/Figtree-SemiBold.ttf') format('woff2');
}

@font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../assets/fonts/Figtree-Bold.ttf') format('woff2');
}
